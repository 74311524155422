.navBrand > a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navBrand {
  display: flex;
  align-items: center;
  gap: 32px;
}

.navArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.launchApp {
  color: #1e2124;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  background: var(--Button-Button-primary, #efeb00);
  padding: 7px 18px;
  transition: 0.3s ease all;
}

.pointer {
  cursor: pointer;
}

.walletIcon {
  width: 20px;
}

.mobLogo {
  display: none !important;
}

@media only screen and (max-width: 1300px) {
  /* 
    .headerBottomContainer .container {
        padding-right: 12px !important;
        padding-left: 12px !important;
    } */

  .headerRIght {
    display: contents;
  }

  ul.headerRIghtBtn {
    display: none !important;
  }

  .headerBtn,
  .navBrand {
    display: contents;
  }

  /* .headerBtn {
        order: 3;
        width: 100%;
        overflow: auto;
        padding: 10px 0;
    } */

  .headerBtn::-webkit-scrollbar {
    display: none;
  }

  .navArea {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 991px) {
  /* a.EmmetTokken {
    display: none;
  } */
}

@media only screen and (max-width: 767px) {
  .navBrand > a {
    display: none;
  }

  .mobLogo {
    display: block !important;
  }

  a.AirdropEmmet {
    font-size: 0;
  }

  a.AirdropEmmet span {
    font-size: 14px;
  }
}
