
.card {
    width: 100%;
    height: 58px;
    background-image: linear-gradient(to right, rgba(95,93,3,1), rgba(47,47,31,1), rgba(32,34,38,1));
    border-radius: 8px;
    border: solid rgba(97,100,107,0.1) 1px;
    display: flex;
    justify-content:center;
    padding: 16px;
    color: white;
    font-size:medium;
    align-items: center;
    margin-bottom: 20px;
}